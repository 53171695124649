.event {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'e-Ukraine-Regular', sans-serif;
}

.event__title {
  margin-bottom: 150px;
}

.event__title-text {
  font-size: 30px;
  font-family: 'e-Ukraine-Regular', sans-serif;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.event__channel {
  font-size: 28px;
  margin-bottom: 250px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-left: 60px;
  }
  @media (max-width: 360px) {
    margin-left: 40px;
  }
}

.event__block-1 {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  border-bottom: 2px solid #000000;
  padding-bottom: 12px;
  text-decoration: none;
  color: #000;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
}

.event__block-1 img {
  width: 173px;
  height: 173px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.event__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 21px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 13px 20px 13px 40px;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 18px;
    margin: 0 auto;
  }
}

.event__additional {
  display: flex;
  align-items: center;

  font-size: 18px;
  color: #777;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.event__views {
  display: flex;
  @media (max-width: 768px) {
    align-items: center;
  }
}

.event__views img {
  width: 55px;
  height: 55px;
  margin-left: 25px;
}

.event__block-2 {
  display: flex;
  margin-top: 20px;
  text-align: center;
  margin-top: 122px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.event__block-2 h3 {
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  font-size: 30px;
  font-family: 'e-Ukraine-Regular', sans-serif;
  align-items: center;
  display: flex;

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
}

.event__block-2 h3::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #f69fed;
  border-radius: 50%;
  left: 65px;
  top: 40%;
  transform: translateY(-50%);
  z-index: -1;
  filter: blur(8px);
}

.event__block-2 p {
  font-size: 31px;
  background-color: #ffffff;
  border: 1px solid #ffff;
  border-radius: 15px;
  padding: 40px;
  font-family: 'e-Ukraine-Regular', sans-serif;
  position: relative;
  margin-left: 0;

  a {
    text-decoration: none;
    color: #000000;
    font-family: 'e-Ukraine-Bold', sans-serif;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 20px;
  }
}

.event__block-2 p::before {
  content: '';
  position: absolute;
  bottom: -14px;
  right: 30px;
  transform: translateX(50%);
  border-width: 0 12px 3px 0px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-top: 20px solid #ffffff;
}

.event__button {
  margin-top: 20px;
  padding: 14px;
  border: 2px solid #000;
  border-radius: 15px;
  font-size: 20px;
  cursor: pointer;
  background-color: #ffffff;
  font-family: 'e-Ukraine-Regular', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #6969f8;
    color: #ffffff;
  }
}
