.footer {
  border-top: 2px solid #000000;
  padding: 71px 0;
  font-size: 25px;
  font-family: 'e-Ukraine-Regular', sans-serif;
  text-align: center;

  &__content {
    .container {
      .footer__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 70px;

        @media (max-width: 768px) {
          flex-direction: column;
          font-size: 20px;
        }

        @media (max-width: 400px) {
          flex-direction: column;
          font-size: 16px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 25px;
    font-family: 'e-Ukraine-Bold', sans-serif;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__social-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #000000;
    }

    & img.footer__icon {
      display: inline-block;
      margin-right: 15px;
      width: 45px;
      height: 45px;
    }
  }

  &__partner-logo {
    width: 221px;
    height: 136px;
  }

  @media (max-width: 768px) {
    .footer__inner {
      gap: 30px;
    }

    .footer__social {
      margin-top: 30px;
    }
  }
}
