.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .banner-main {
    img {
      width: 100%;
      height: auto;
      margin-bottom: 97px;
      @media (max-width: 768px) {
        margin-bottom: 60px;
      }
    }
  }

  .mission {
    display: flex;
    align-items: center;
    gap: 95px;
    padding: 0px 115px;

    h2 {
      white-space: nowrap;
      justify-content: center;
      font-family: 'e-Ukraine-Bold', sans-serif;
      font-size: 33px;
    }

    p {
      font-family: 'e-Ukraine-Regular', sans-serif;
      font-size: 25px;
    }
  }

  @media (max-width: 768px) {
    .mission {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 30px;
      align-items: center;
    }

    .mission h2 {
      font-size: 20px;
    }

    .mission p {
      font-size: 18px;
    }
  }

  .direction {
    margin-top: 100px;

    h2 {
      display: flex;
      justify-content: center;
      margin-bottom: 105px;
      font-family: 'e-Ukraine-Bold', sans-serif;
      font-size: 36px;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .direction__item {
      width: calc(33.33% - 20px);
      margin-bottom: 48px;
    }

    .direction__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      img {
        width: 300px;
        height: 240px;
      }

      span {
        margin-top: 49px;
        text-align: center;
        font-family: 'e-Ukraine-Regular', sans-serif;
        font-size: 22px;
        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
    }

    @media (max-width: 992px) {
      .direction__item {
        width: calc(50% - 20px);
      }
    }

    @media (max-width: 768px) {
      h2 {
        margin-bottom: 40px;
        font-size: 20px;
      }

      .direction__item {
        width: 100%;
      }
    }

    &__subtitle {
      margin: 118px 0 127px 0;
      padding: 30px;
      position: relative;

      p {
        font-size: 22px;
        b {
          font-family: 'e-Ukraine-Bold', sans-serif;
        }
        font-family: 'e-Ukraine-Regular', sans-serif;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
    .direction__subtitle::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 30px;
      background-color: #ffa613;
      left: 10px;
      transform: translateY(-350%);
      z-index: -1;
    }

    .direction__subtitle::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #ffa613;
      border-radius: 50%;
      left: 10px;
      transform: translateY(400%);
      z-index: -1;
    }
  }
}

.social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'e-Ukraine-Regular', sans-serif;
    font-size: 32px;
    margin-bottom: 75px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      margin: 0 15px 136px 15px;
      cursor: pointer;
      width: 120px;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  .social-media {
    h2 {
      font-size: 20px;
    }
  }
}

.photogallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;

  &__title {
    font-family: 'e-Ukraine-Bold', sans-serif;
    font-size: 35px;
    margin-bottom: 120px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .photogallery {
    &__title {
      font-size: 20px;
    }
  }
}

.donate {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 97px;
  margin-bottom: 184px;
  flex-wrap: wrap;

  &__title {
    font-family: 'e-Ukraine-Regular', sans-serif;
    font-size: 32px;
  }

  &__image {
    cursor: pointer;
    width: 106px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .donate {
    &__title {
      font-size: 20px;
    }
  }
}
