.container {
  .blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    &__title {
      font-family: 'e-Ukraine-Bold', sans-serif;
      font-size: 33px;
      margin-bottom: 20px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }

    &__subtitle {
      font-family: 'e-Ukraine-Regular', sans-serif;
      font-size: 20px;
      margin-bottom: 40px;
      text-align: center;

      a {
        color: #6969f8;
        text-decoration: none;
        transition: color 0.3s ease;

        &:active {
          color: #2e2ed1;
        }
      }

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    &__posts {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      max-width: 900px;
      margin-bottom: 70px;

      @media (max-width: 768px) {
        gap: 20px;
      }
    }

    .blog-post {
      display: flex;
      align-items: flex-start;
      background: #f5f5f5;
      border-radius: 15px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      &__photo {
        margin-right: 20px;

        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }

          @media (max-width: 768px) {
            width: 100px;
            height: 100px;
            margin-bottom: 15px;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;

        h3 {
          font-family: 'e-Ukraine-Bold', sans-serif;
          font-size: 24px;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 20px;
          }
        }

        p {
          margin: 15px 0;
          font-family: 'e-Ukraine-Regular', sans-serif;
          font-size: 18px;
          color: #555;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }

        .blog-post__info {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          .author {
            font-family: 'e-Ukraine-Bold', sans-serif;
            font-size: 16px;
            color: #333;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          .date {
            font-family: 'e-Ukraine-Regular', sans-serif;
            font-size: 14px;
            color: #777;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
