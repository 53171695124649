.detail {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    padding: 11px;
  }
}

.detail__image {
  margin-right: 50px;
}

.detail__image img {
  width: 420px;
  height: 420px;
  @media (max-width: 768px) {
    width: 350px;
    height: 350px;
  }
  @media (max-width: 360px) {
    width: 300px;
    height: 300px;
  }
}

.detail__description {
  flex: 1;
}

.detail__text-1 {
  font-size: 30px;
  font-family: 'e-Ukraine-Bold', sans-serif;
  margin-bottom: 148px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.detail__date p {
  font-size: 16px;
  color: #b5b5b5;
}

.detail__text-2 {
  font-size: 20px;
  font-family: 'e-Ukraine-Regular', sans-serif;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.detail__error {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: 'e-Ukraine-Bold', sans-serif;
  margin-bottom: 150px;
  margin-top: 50px;
}
