.container {
  .dialog {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 52px;
    margin-bottom: 88px;

    &__box-1,
    &__box-3 {
      background-color: #ffffff;
      border: 1px solid #ffff;
      border-radius: 30px;
      padding: 22px;
      width: 100%;
      max-width: 950px;
      margin-bottom: 30px;
      position: relative;
      font-size: 27px;
      font-family: 'e-Ukraine-Regular', sans-serif;

      &::before {
        content: '';
        position: absolute;
        bottom: -28px;
        left: 10px;
        transform: translateX(50%);
        border-width: 0 18px 3px 0px;
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 40px solid transparent;
        border-top: 30px solid #ffffff;
      }
    }

    &__box-2 {
      background-color: #ffffff;
      border: 1px solid #ffff;
      border-radius: 30px;
      padding: 22px;
      width: 700px;
      margin-left: 240px;
      margin-bottom: 30px;
      position: relative;
      font-size: 27px;
      font-family: 'e-Ukraine-Regular', sans-serif;

      a {
        color: #6969f8;
        text-decoration: none;
        cursor: pointer;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -28px;
        right: 55px;
        transform: translateX(50%);
        border-width: 0 18px 3px 0px;
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 40px solid transparent;
        border-top: 30px solid #ffffff;
      }

      @media (max-width: 768px) {
        margin: 30px auto;
      }
    }

    p {
      font-family: 'e-Ukraine-Regular', sans-serif;
      font-size: 27px;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
  .slider-network {
    display: flex;
    justify-content: center;
    margin-bottom: 168px;
  }
}
