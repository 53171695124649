.container {
  max-width: 1230px;
  padding: 0 10px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  padding: 57px 0;
  gap: 51px;
  justify-content: space-between;
}

.header__menu {
  ul {
    font-family: 'e-Ukraine-Regular', sans-serif;
    font-size: 18px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 24px;

    li {
      position: relative;
      padding-bottom: 16px;
      &:hover {
        color: #6969f8;
        cursor: pointer;
      }

      a {
        color: #000;
        text-decoration: none;
        position: relative;

        &.active {
          color: #6969f8;
          border-bottom: 3px solid #000;
          padding-bottom: 4px;
        }

        &:hover {
          color: #6969f8;
        }
      }
    }
  }
  @media (max-width: 990px) {
    display: none;
  }
}

.language-switcher {
  position: relative;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .language-popup {
    position: absolute;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

    ul {
      list-style: none;
      margin: 0;
      padding: 10px;

      li:hover {
        color: #fff;
        background-color: #6969f8;
      }

      li {
        margin-bottom: 10px;
        font-family: 'e-Ukraine-Regular', sans-serif;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

.burger {
  display: none;

  @media (max-width: 990px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 30px;
    cursor: pointer;
    flex-wrap: wrap;
    margin: 0 0 20px 30px;

    .line {
      width: 100%;
      height: 3px;
      background-color: #000;
      margin-bottom: 5px;
      transition: background-color 0.3s ease-in-out;
    }

    &.open {
      .line {
        background-color: #6969f8;
      }
    }
  }
}

.mobile-menu {
  display: none;

  @media (max-width: 990px) {
    display: block;
    margin: 20px 0 30px 35px;
    background-color: #ffff;
    width: 290px;
    padding: 10px;
    line-height: 60px;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

    ul {
      list-style: none;
      margin: 0;
      padding: 10px;

      li {
        margin-bottom: 10px;

        a {
          color: #000;
          text-decoration: none;
          font-family: 'e-Ukraine-Regular', sans-serif;
          font-size: 20px;

          &:hover {
            color: #fff;
            background-color: #6969f8;
          }
        }
      }
    }
  }
}
