.news-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 63px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  &__image img {
    width: 599px;
    height: 418px;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  &__text-1 p {
    font-size: 30px;
    font-family: 'e-Ukraine-Bold', sans-serif;

    @media (max-width: 768px) {
      font-size: 18px;
      padding: 10px;
      text-align: center;
      justify-content: center;
    }
  }

  &__date p {
    font-size: 16px;
    color: #acacac;
    font-family: 'e-Ukraine-Regular', sans-serif;
  }

  &__text-2 p {
    font-size: 20px;
    font-family: 'e-Ukraine-Regular', sans-serif;
    padding: 10px;

    @media (max-width: 768px) {
      font-size: 18px;
      padding: 10px;
      justify-content: center;
    }
  }

  &__text-2 {
    margin-bottom: 150px;

    a {
      text-decoration: none;
      font-weight: bold;
      color: #6969f8;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 418px) {
        font-size: 14px;
      }
    }
  }
}

.news__error {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: 'e-Ukraine-Bold', sans-serif;
  margin-bottom: 150px;
  margin-top: 50px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}
