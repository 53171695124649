.slider-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .slider-content {
    flex: 1;
    cursor: pointer;
  }

  .slider-photo {
    width: 100%;
    height: auto;
    display: block;
  }

  .slider-arrow {
    width: 40px;
    height: 40px;
    background: rgba(226, 223, 223, 0.7);
    border: 1px solid #c5c1c1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;

    img {
      width: 32px;
      height: 32px;
    }

    &.arrow-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.arrow-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}
